import React from 'react'
import Image from 'components/Image'
import { graphql } from 'gatsby'
import Modal from 'components/Modal/CustomModal'

export default function WorkaroundPage ({
  data: {
    highlights,
    properties,
    propertyGroups,
    accessories,
    accessoryGroups,
    products,
    modals
  }
}) {
  return (
    <>
      {products.edges.map(({ node }) => (
        <div style={{ height: 433, maxWidth: 334 }}>
          <Image src={node.component.image} />
        </div>
      ))}
      {highlights.edges.map(({ node }) => (
        <div style={{ height: 240, maxWidth: 700 }}>
          <Image src={node.component.image} />
        </div>
      ))}
      {[
        highlights,
        properties,
        propertyGroups,
        accessories,
        accessoryGroups
      ].map(({ edges }) =>
        edges.map(({ node }) => (
          <div style={{ maxWidth: 200 }}>
            <Image src={node.component.image} />
          </div>
        ))
      )}
      {[
        highlights,
        properties,
        propertyGroups,
        accessories,
        accessoryGroups
      ].map(({ edges }) =>
        edges.map(({ node }) => (
          <div style={{ maxWidth: 50 }}>
            <Image src={node.component.image} />
          </div>
        ))
      )}
      {modals.edges.map(({ node }) => (
        <Modal data={node} />
      ))}
    </>
  )
}

export const pageQuery = graphql`
  query {
    highlights: allStoryblokProductHighlightStory {
      edges {
        node {
          ...storyblokProductHighlightStory
        }
      }
    }
    properties: allStoryblokProductPropertyStory {
      edges {
        node {
          ...storyblokProductPropertyStory
        }
      }
    }
    propertyGroups: allStoryblokProductPropertyGroupStory {
      edges {
        node {
          ...storyblokProductPropertyGroupStory
        }
      }
    }
    accessories: allStoryblokProductAccessoryStory {
      edges {
        node {
          ...storyblokProductAccessoryStory
        }
      }
    }
    accessoryGroups: allStoryblokProductAccessoryGroupStory {
      edges {
        node {
          ...storyblokProductAccessoryGroupStory
        }
      }
    }
    products: allStoryblokProductStory {
      edges {
        node {
          ...storyblokProductStory
        }
      }
    }
    modals: allStoryblokModalStory {
      edges {
        node {
          ...storyblokModalStory
        }
      }
    }
  }
`
